import { DividerHorizontal12, SpacingHeight28, SpacingHeight40 } from '@banksalad/bpl-web';
import { useEffect } from 'react';

import { FetchError } from '@/components/fetch-error';
import { Loader } from '@/components/loader';
import {
  useFetchFundInvestments,
  useFetchInvestmentRevenues,
  useFetchStockInvestments,
} from '@/hooks/fetch';
import {
  trackExposeViewAssetInvestDashboardExp,
  trackExposeViewInvestDashboardExp,
} from '@/trackers/dashboard-exp';
import { isAndroid } from '@/utils/extension';
import { checkAssets, refineInvestmentRevenue } from '@/views/dashboard-exp/controller';
import {
  EmptyInvestmentSummary,
  InvestmentSummary,
} from '@/views/dashboard-exp/investment-summary';
import { EmptyPortfolio, Portfolio } from '@/views/dashboard-exp/portfolio';
import { DashboardTop } from '@/views/dashboard-exp/top';

export const DashboardExp = () => {
  // FETCH DATA
  const {
    data: investmentRevenueData,
    isLoading: fetchInvestmentRevenuesLoading,
    isError: fetchInvestmentRevenuesError,
    isSuccess: fetchInvestmentRevenuesSuccess,
  } = useFetchInvestmentRevenues();
  const {
    isLoading: stockLoading,
    isError: stockError,
    isSuccess: stockSuccess,
  } = useFetchStockInvestments();
  const {
    isLoading: fundLoading,
    isError: fundError,
    isSuccess: fundSuccess,
  } = useFetchFundInvestments();

  useEffect(() => {
    if (fetchInvestmentRevenuesSuccess && stockSuccess && fundSuccess) {
      // 안드로이드 분기 필요
      // https://banksalad.slack.com/archives/C014VHKQ76U/p1646381103336839?thread_ts=1644301751.904349&cid=C014VHKQ76U
      if (isAndroid()) {
        trackExposeViewInvestDashboardExp();
      } else {
        trackExposeViewAssetInvestDashboardExp();
      }
    }
  }, [fetchInvestmentRevenuesSuccess, stockSuccess, fundSuccess]);

  // RENDER LOADING / ERROR CASE
  if (fetchInvestmentRevenuesLoading || stockLoading || fundLoading) {
    return <Loader />;
  }
  if (fetchInvestmentRevenuesError || stockError || fundError) {
    return <FetchError onRetry={window.location.reload} />;
  }

  // SUCCESS CASE
  const nonNullInvestmentRevenueData = investmentRevenueData!;
  const { totalValuation } = refineInvestmentRevenue(nonNullInvestmentRevenueData);

  const { hasInvestmentAssets, hasAccountAssets } = checkAssets([totalValuation]);
  const isFullEmpty = !hasInvestmentAssets && !hasAccountAssets;

  return (
    <>
      <DashboardTop totalValuation={totalValuation} />
      {hasInvestmentAssets ? (
        <InvestmentSummary investmentRevenue={nonNullInvestmentRevenueData} />
      ) : (
        <EmptyInvestmentSummary />
      )}
      <SpacingHeight28 />
      <DividerHorizontal12 />

      {isFullEmpty ? (
        <EmptyPortfolio />
      ) : (
        <Portfolio investmentRevenueData={nonNullInvestmentRevenueData} />
      )}
      <SpacingHeight40 />
    </>
  );
};
